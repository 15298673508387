import styled from 'styled-components';

interface ISliderWrapperProps {
  leftShift?: number;
  $peek?: 'none' | 'both' | 'left' | 'right';
  extraWidth?: number;
  parent?: 'cardCarousel' | 'contentSlider' | 'categorySubcategory';
}

export const SliderWrapper = styled.div<ISliderWrapperProps>`
  position: relative;
  z-index: 10;
  &.feature-slider {
    padding-right: 0;
    margin-right: 0;
    overflow: hidden;
    .slick-track {
      left: 3vw;
    }
    .slick-initialized {
      .slick-slide {
        padding: 0 0 50px 10px;
      }
    }
    @media screen and (max-width: 480px) {
      .slick-track {
        left: 0;
      }
    }
  }
  &.sub-category {
    .subcategory-card {
      padding-right: 20px;
    }
    @media screen and (max-width: 479px) {
      padding: 4%;
      .subcategory-card {
        padding: 0 10px;
      }
    }
  }
  &.subcategory-slider {
    .slick-track {
      float: left;
    }
  }
  &.card-carousel {
    .slick-initialized .slick-slide {
      padding-left: 10px;
      padding-right: 10px;
      @media screen and (min-width: 768px) {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
  @media screen and (min-width: 480px) {
    ${({ $peek = 'none' }) =>
      $peek !== 'none' &&
      `
    overflow: hidden;
    margin-left: ${$peek === 'both' || $peek === 'left' ? '-50px' : '0'};
    padding-left: ${$peek === 'both' || $peek === 'left' ? '50px' : '1'};
    margin-right: ${$peek === 'both' || $peek === 'right' ? '-30px' : '0'};
    padding-right: ${$peek === 'both' || $peek === 'right' ? '30px' : '1'};
    .slick-list { overflow: visible; }

    ${({ extraWidth }: ISliderWrapperProps) => extraWidth && `width: calc(100% + ${extraWidth}px);`}
  `};
  }
`;
